.overlay {
  background: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.fixed {
  position: sticky;
}