.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  height: 90vh;
  padding-left: 4vw;
  padding-right: 4vw;
  /* overflow:auto;
    flex-wrap: nowrap; */
}

.long {
  height: 90vh;
  padding-top: 2vh;
  padding-bottom: 2vh;

  box-sizing: border-box;
}

.page-item {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.no-padding {

  padding-left: 0vw;
  padding-right: 0vw;
}